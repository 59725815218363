import React,{useEffect,useState} from 'react'
import {useNavigate,useLocation} from 'react-router-dom' 
import axios from 'axios';
const queryString = require('query-string'); 
const App = () => { 
    const [data,setData] = useState(null)
    const nav = useNavigate()
    const loc = useLocation() 
    const firebaseConfig = {
      apiKey: "AIzaSyChnJt8uDGYnzS3R2NFNv2keoafOUSQ1x8",
      authDomain: "theukrainianfund.firebaseapp.com",
      projectId: "theukrainianfund",
      storageBucket: "theukrainianfund.appspot.com",
      messagingSenderId: "488964304566",
      appId: "1:488964304566:web:d582abf448e5d511b20017",
      measurementId: "G-RS42S3PFQ7"
    };
    useEffect(()=>{
      
      let params = queryString.parse(loc.search)     
      const newParms = {...params,...firebaseConfig}
      let newparmString = '/'+Array.from(loc.pathname).splice(1).join('') 
      Object.keys(newParms).forEach((item,index)=>{
        if(index===0){
          newparmString = newparmString + "?"+item+"="+newParms[item]
        }else{
          newparmString = newparmString + "&"+item+"="+newParms[item]
        }
      }) 
      console.log("param string "+ newparmString)
            nav(newparmString)
            setData(<div style={{height:'100%',position:'absolute',top:0,bottom:0,left:0,right:0,overflow:'hidden'}}>
            <object  style={{height:'100%',position:'absolute',top:0,bottom:0,left:0,right:0,overflow:'hidden'}}  type="text/html" data={"https://buildservemain.web.app"+newparmString} width="100%" height="100%"  >
            </object> 
            </div>)
        
    },[])
    useEffect(()=>{

    },[data])
  return ( 
    data
  )
}

export default App